<div class="mb-2">
    <label class="form-label">{{ header }}</label>
    <div class="input-group mb-2">
        <input
            type="text"
            class="form-control"
            [(ngModel)]="addOrEditKey"
            placeholder="{{ keyPlaceHolder }}"
            (change)="onKeyChange()"
        />
        <input type="text" class="form-control" [(ngModel)]="addOrEditValue" placeholder="{{ valuePlaceHolder }}" />
        <button class="btn btn-primary" (click)="addOrEdit()" type="button">
            <span *ngIf="!isEdit">{{ 'Add' | localize }}</span>
            <span *ngIf="isEdit">{{ 'Edit' | localize }}</span>
        </button>
    </div>
    <div class="key-value-items-list" *ngIf="items">
        <div
            *ngFor="let keyValueItem of items"
            class="alert alert-custom alert-white alert-bold m-1 min-h-60px"
            [class.alert-white]="addOrEditKey !== keyValueItem.key"
            [class.alert-warning]="addOrEditKey === keyValueItem.key"
            role="alert"
        >
            "{{ keyValueItem.key }}" : "{{ keyValueItem.value }}"

            <button type="button" class="btn btn-danger float-end btn-sm m-0 p-3" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true" (click)="removeItem(keyValueItem)"><i class="la la-close fa-1x m-0 p-0"></i></span>
            </button>

            <button type="button" class="btn btn-primary float-end btn-sm m-0 me-1 p-3">
                <span aria-hidden="true" (click)="openItemEdit(keyValueItem)">
                    <i class="la la-edit fa-1x m-0 p-0"></i>
                </span>
            </button>
        </div>
    </div>
</div>
