<div
    appBsModal
    #changePasswordModal="bs-modal"
    (onShown)="onShown()"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
    [config]="{ backdrop: 'static' }"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #changePasswordModalForm="ngForm" (ngSubmit)="save()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{ 'ChangePassword' | localize }}</span>
                    </h5>
                    <button type="button" class="btn-close" [attr.aria-label]="l('Close')" (click)="close()">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="mb-5" data-kt-password-meter="true">
                        <label class="form-label" for="CurrentPassword">{{ 'CurrentPassword' | localize }}</label>
                        <div class="position-relative mb-3">
                            <input
                                id="CurrentPassword"
                                #currentPasswordInput="ngModel"
                                type="password"
                                name="CurrentPassword"
                                class="form-control"
                                [(ngModel)]="currentPassword"
                                required
                            />
                            
                            <!--begin::Visibility toggle-->
                            <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                            data-kt-password-meter-control="visibility">
                                <i class="fas fa-eye-slash fs-4"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                                <i class="fas fa-eye d-none fs-4"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                            </span>
                            <!--end::Visibility toggle-->
                        </div>
                        <validation-messages [formCtrl]="currentPasswordInput"></validation-messages>
                    </div>
                    <div class="mb-5" data-kt-password-meter="true">
                        <label class="form-label" for="NewPassword">{{ 'NewPassword' | localize }}</label>
                        <div class="position-relative mb-3">
                            <input
                                id="NewPassword"
                                type="password"
                                name="NewPassword"
                                class="form-control"
                                [(ngModel)]="password"
                                #NewPassword="ngModel"
                                validateEqual="NewPasswordRepeat"
                                reverse="true"
                                [requireDigit]="passwordComplexitySetting.requireDigit"
                                [requireLowercase]="passwordComplexitySetting.requireLowercase"
                                [requireUppercase]="passwordComplexitySetting.requireUppercase"
                                [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric"
                                [requiredLength]="passwordComplexitySetting.requiredLength"
                                required
                            />
                                                    <!--begin::Visibility toggle-->
                            <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                                    data-kt-password-meter-control="visibility">
                                <i class="fas fa-eye-slash fs-4"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                                <i class="fas fa-eye d-none fs-4"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                            </span>
                            <!--end::Visibility toggle-->
                        </div>
                    </div>
                    <div [hidden]="changePasswordModalForm.form.valid || changePasswordModalForm.form.pristine">
                        <ul class="help-block text-danger" *ngIf="NewPassword.errors">
                            <li [hidden]="!NewPassword.errors.requireDigit">
                                {{ 'PasswordComplexity_RequireDigit_Hint' | localize }}
                            </li>
                            <li [hidden]="!NewPassword.errors.requireLowercase">
                                {{ 'PasswordComplexity_RequireLowercase_Hint' | localize }}
                            </li>
                            <li [hidden]="!NewPassword.errors.requireUppercase">
                                {{ 'PasswordComplexity_RequireUppercase_Hint' | localize }}
                            </li>
                            <li [hidden]="!NewPassword.errors.requireNonAlphanumeric">
                                {{ 'PasswordComplexity_RequireNonAlphanumeric_Hint' | localize }}
                            </li>
                            <li [hidden]="!NewPassword.errors.requiredLength">
                                {{
                                    'PasswordComplexity_RequiredLength_Hint'
                                        | localize: passwordComplexitySetting.requiredLength
                                }}
                            </li>
                        </ul>
                    </div>
                    <div class="mb-5" data-kt-password-meter="true">
                        <label class="form-label" for="NewPasswordRepeat">{{ 'NewPasswordRepeat' | localize }}</label>
                        <div class="position-relative mb-3">
                            <input
                                id="NewPasswordRepeat"
                                type="password"
                                name="NewPasswordRepeat"
                                class="form-control"
                                [ngModel]="confirmPassword"
                                #NewPasswordRepeat="ngModel"
                                validateEqual="NewPassword"
                                [requireDigit]="passwordComplexitySetting.requireDigit"
                                [requireLowercase]="passwordComplexitySetting.requireLowercase"
                                [requireUppercase]="passwordComplexitySetting.requireUppercase"
                                [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric"
                                [requiredLength]="passwordComplexitySetting.requiredLength"
                                reverse="false"
                                required
                            />
                            <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                            data-kt-password-meter-control="visibility">
                                <i class="fas fa-eye-slash fs-4"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                                <i class="fas fa-eye d-none fs-4"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                            </span>
                            <!--end::Visibility toggle-->
                        </div>
                    </div>
                    <div [hidden]="changePasswordModalForm.form.valid || changePasswordModalForm.form.pristine">
                        <ul class="help-block text-danger" *ngIf="NewPasswordRepeat.errors">
                            <li [hidden]="!NewPasswordRepeat.errors.requireDigit">
                                {{ 'PasswordComplexity_RequireDigit_Hint' | localize }}
                            </li>
                            <li [hidden]="!NewPasswordRepeat.errors.requireLowercase">
                                {{ 'PasswordComplexity_RequireLowercase_Hint' | localize }}
                            </li>
                            <li [hidden]="!NewPasswordRepeat.errors.requireUppercase">
                                {{ 'PasswordComplexity_RequireUppercase_Hint' | localize }}
                            </li>
                            <li [hidden]="!NewPasswordRepeat.errors.requireNonAlphanumeric">
                                {{ 'PasswordComplexity_RequireNonAlphanumeric_Hint' | localize }}
                            </li>
                            <li [hidden]="!NewPasswordRepeat.errors.requiredLength">
                                {{
                                    'PasswordComplexity_RequiredLength_Hint'
                                        | localize: passwordComplexitySetting.requiredLength
                                }}
                            </li>
                            <li [hidden]="NewPasswordRepeat.valid">{{ 'PasswordsDontMatch' | localize }}</li>
                        </ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-light-primary fw-bold"
                        (click)="close()"
                        [disabled]="saving"
                    >
                        {{ 'Cancel' | localize }}
                    </button>
                    <button
                        type="submit"
                        class="btn btn-primary fw-bold"
                        [disabled]="!changePasswordModalForm.form.valid || saving"
                    >
                        <i class="fa fa-save"></i>
                        <span>{{ 'Save' | localize }}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
